<template>
  <div>
    <v-container class="container--fluid">
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-col cols="12" class="pl-0 pr-0 pb-1">
              <v-row>
                <v-spacer />

                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="Tarih"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker v-model="startDate" no-title scrollable>
                      <v-spacer />
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <!--   <v-flex xs3 sm2 md2 class="pr-3">
                  <v-select
                    v-model="channelModel"
                    :items="channel"
                    label="Cihaz"
                  ></v-select>
                </v-flex> -->

                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-select
                    v-model="santralModel"
                    :items="santralDataList"
                    multiple
                    label="Santral"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ channelModel.length - 1 }} others)
                      </span>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="3" sm="1" md="1" class="pr-3 pt-3">
                  <v-btn color="primary" @click="getErrorTable">
                    Filtrele
                  </v-btn>
                </v-col>

                <v-col cols="3" sm="1" md="1" class="pr-3 pt-3">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        fab
                        dark
                        small
                        color="cyan"
                        v-on="on"
                        @click="exportDocument"
                      >
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span>Export to Excel</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" class="fullgridtable pl-0 pr-0 pb-1">
              <v-card>
                <v-data-table
                  :id="santralDataTable"
                  dense
                  :items-per-page.sync="itemsPerPage"
                  :server-items-length="itemsLength"
                  :footer-props="{
                    'items-per-page-options': [5, 10, 20, 30, 40, 50, 100],
                    showFirstLastPage: true,
                    itemsPerPageText: 'Limit',
                  }"
                  :options.sync="options"
                  :headers="headers"
                  :page.sync="currentPage"
                  :loading="loading"
                  loading-text="Yükleniyor... Lütfen bekleyiniz"
                  :items="desserts"
                  class="elevation-1"
                >
                  <template v-slot:item.passivetime="{ item }">
                    <span v-html="setTimeStamp(item.passivetime)" />
                  </template>

                  <template v-slot:item.finishMailSendDate="{ item }">
                    <span v-html="setTime(item.finishMailSendDate)" />
                  </template>

                  <template v-slot:item.mailSendDate="{ item }">
                    <span v-html="setTime(item.mailSendDate)" />
                  </template>

                  <template v-slot:item.updateTime="{ item }">
                    <span v-html="setTime(item.updateTime)" />
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <ExportDocument :export-document-open="exportDocumentOpen" />
  </div>
</template>
<script>
import * as Notification from "@/api/Notification/notification";
import moment from "moment";
import ExportDocument from "@/views/components/Shared/ExportDocument.vue";

export default {
  name: "GeneralStatsHour",
  components: {
    ExportDocument,
  },
  data: () => ({
    santralModel: [],
    chartData: {},
    startDate: moment().format("YYYY-MM-DD"),
    finishDate: moment().format("YYYY-MM-DD"),
    menu: false,
    modal: false,
    menu2: false,
    itemsPerPage: 10,
    itemsLength: 10,
    headers: [],
    desserts: [],
    channelModel: "STRINGBOX",
    channel: [
      { text: "String", value: "STRINGBOX" },
      { text: "İnverter", value: "INVERTER" },
    ],
    exportDocumentOpen: {
      open: false,
      tableID: null,
    },
    santralDataTable: "",
    chartText: "",
    newData: "true",
    currentPage: 1,
    loading: false,
    options: {},
    params: {},
  }),
  computed: {
    santralDataList() {
      return this.$store.getters.santralDataList;
    },
  },
  watch: {
    reportTypeModel() {
      this.getErrorTable();
    },

    options: {
      handler() {
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        this.params = {
          type: "Error",
          skip: (page - 1) * itemsPerPage,
          limit: itemsPerPage,
        };

        if (sortBy.length > 0) {
          this.params.orderBys = [
            { fieldName: sortBy[0], sortType: sortDesc[0] },
          ];
        }

        this.getErrorTable();
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    setTime(time) {
      if (time !== undefined) {
        return moment(time).format("HH:mm");
      }
    },
    setTimeStamp(time) {
      if (time !== undefined) {
        if (typeof time === "string") {
          return moment(time).format("HH:mm");
        } else {
          return moment.unix(time).format("HH:mm");
        }
      }
    },

    exportDocument() {
      this.exportDocumentOpen.open = true;
      this.exportDocumentOpen.tableID = this.santralDataTable;
    },
    getErrorTable() {
      this.loading = true;

      this.finishDate = this.startDate;
      this.headers = [
        { text: "Tip", value: "type" },
        { text: "Kod", value: "code" },
        { text: "Santral", value: "santral" },
        { text: "Cihaz", value: "device" },
        { text: "Alt Cihaz", value: "subdevice" },
        { text: "Açıklama", value: "message" },
        { text: "Başlangıç Tarih", value: "createTime" },
        { text: "İlk Mail", value: "mailSendDate" },
        { text: "Güncelleme Tarih", value: "updateTime" },
        { text: "Bitiş Tarih", value: "passivetime" },
        { text: "Bitiş Mail", value: "finishMailSendDate" },
        { text: "Hata süresi", value: "lifetime" },
      ];

      if (this.santralModel.length > 0) {
        this.params.santral = this.santralModel;
      }

      this.params.startDate = this.startDate;
      this.params.finishDate = this.finishDate;
      const self = this;

      const tempData = Notification.default.getError(this.params);
      tempData.then((res) => {
        self.desserts = res.data;
        self.itemsLength = res.count;
        self.forceRerender();
        self.loading = false;
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>

<style scoped>
.theme--dark
  .fullgridtable
  .theme--light.v-data-table
  tbody
  tr:not(:last-child) {
  border-bottom: none;
}

.fullgridtable .v-data-table th {
  border: thin solid #0000001f;
}

.fullgridtable .v-data-table td {
  border: thin solid #0000001f;
}

.fullgridtable.theme--dark .v-data-table th,
.fullgridtable.theme--dark .v-data-table td {
  border: thin solid hsla(0, 0%, 100%, 0.12);
}

.fullgridtable .theme--light.v-data-table tbody tr:not(:last-child) {
  border-bottom: none;
}

.fullgridtable.fullorder .v-data-table th,
.fullgridtable.fullorder .v-data-table td {
  border-bottom: 0 !important;
}

.fullgridtable.fullorder .v-data-table td:not(:last-child),
.fullgridtable.fullorder .v-data-table th:not(:last-child) {
  border-right: 0 !important;
}
</style>
